<template>
    <modal ref="modalDetallePago" titulo="Buscar Pedido" tamano="modal-lg" no-cancelar no-aceptar>
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center">
            <div class="col-auto d-middle">
                <el-input
                v-model="buscar"
                placeholder="Buscar pedido"
                size="small"
                prefix-icon="icon-search"
                class="cabecera-busqueda mx-1 br-20"
                />
            </div>
            <div class="col-auto btn-general mx-2 f-14 px-3" @click="controlBuscarPedido">
                Buscar
            </div>
            <div v-if="mostrarInfo" class="col-auto btn-outline mx-2 f-14 px-3" @click="verLiquidacionCompleta">
                Ver liquidación completa
            </div>
        </div>
        <template v-if="mostrarInfo">
            <div class="row mx-0 align-items-center my-4 bg-white br-8 py-1 ml-4">
                <div class="col-2">
                    <p class="f-18 text-general f-600">
                        {{ pedido.id }}
                    </p>
                    <p class="text-general f-400 f-14">
                        <i class="icon-pedidos f-17" />
                        Pedido
                    </p>
                </div>
                <div class="col">
                    <p class="f-18 text-general f-600">
                        {{ separadorNumeroDecimales(pedido.valor_pedido) }} COP
                    </p>
                    <p class="text-general f-400 f-14">
                        <i class="icon-comision f-14" /> 
                        Valor pedido
                    </p>
                </div>
                <div class="col">
                    <p class="f-18 text-general f-600">
                        {{ pedido.entrega_fecha | helper-fecha('DD MMM Y') }}
                    </p>
                    <p class="text-general f-400 f-14">
                        <i class="icon-calendar-outline f-17" />
                        Fecha de pedido
                    </p>
                </div>
                <div class="col">
                    <p class="f-18 text-general f-600">
                        {{ separadorNumeroDecimales(pedido.valor_condicion) }} COP
                    </p>
                    <p class="text-general f-400 f-14">
                        <i class="icon-price-check f-17" />
                        Comisión
                    </p>
                </div>
            </div>
            <div class="row mx-0 align-items-center my-3 mt-4 ml-4">
                <img :src="pedido.logo_lechero != null ? pedido.logo_lechero : '/img/no-imagen/sin_cliente.svg'" width="55" height="55" class="rounded-circle border" />
                <div class="col-6">
                    <p class="text-general f-17 f-500">
                        {{ pedido.leechero }}
                    </p>
                    <p class="text-general f-13">
                        Leechero
                    </p>
                </div>
            </div>
            <div class="row mx-0 align-items-center my-3 mt-4 ml-4">
                <img :src="pedido.foto_encargado != null ? pedido.foto_encargado : '/img/no-imagen/sin_cliente.svg'" width="55" height="55" class="rounded-circle border" />
                <div class="col-6">
                    <p class="text-general f-17 f-500">
                        {{ pedido.encargado }}
                    </p>
                    <p class="text-general f-13">
                        Encargado del pago - {{ pedido.fecha_pago | helper-fecha('DD MMM Y, h:mm a') }}
                    </p>
                </div>
            </div>
            <div class="row text-general my-3 mt-4 ml-4">
                <div class="col-10 px-0 ml-5">
                    <p class="f-16 f-500">Observacion del pago</p>
                </div>
                <p class="f-14 ml-5">
                    {{ pedido.comentario }}
                </p>
            </div>
        </template>
        <template v-else>
            <template v-if="mostrarError">
                <div class="row mx-0 align-items-center mt-5">
                    <div v-if="tipoError == 'noExiste'" class="col-12 text-center">
                        <p class="text-danger w-100 f-14">El pedido ingresado no existe</p>
                    </div>
                    <div v-if="tipoError == 'noExisteEnCedis'" class="col-12 text-center">
                        <p class="text-danger w-100 f-14">El pedido ingresado no existe en cedis</p>
                    </div>
                    <div v-if="tipoError == 'noLiquidado'" class="col-12 text-center">
                        <p class="text-danger w-100 f-14">Este pedido no ha sido liquidado</p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row mx-0 align-items-center mt-5">
                    <div class="col-12 text-center">
                        <p class="f-14">No se ha realizado una búsqueda</p>
                    </div>
                </div>
            </template>
        </template>
    </modal>
</template>

<script>
import Liquidacion from '~/services/comisiones/liquidar'
export default {
    data(){
        return {
            cargando: false,
            mostrarInfo: false,
            mostrarError: false,
            pedido: {},
            buscar: '',
            tipoError: '',
        }
    },
    methods: {
        controlBuscarPedido(){
            this.mostrarError = false;
            this.mostrarInfo = false;
            this.pedido = {};
            this.buscarPedidos();
        },
        async buscarPedidos(){
            try {
                if(this.buscar == '' || this.buscar == null)return;
                this.cargando = true;
                const {data} = await Liquidacion.getPedidos(this.buscar)
                if(!data.exito){
                    this.mostrarInfo = false;
                    this.tipoError = data.tipoError;
                    this.mostrarError = true;
                    return;
                }
                this.pedido = data.pedido;
                this.mostrarInfo = true;
            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargando = false;
            }
        },
        verLiquidacionCompleta(){
            this.$emit('verLiquidacion',this.pedido.id_alianza);
            this.$refs.modalDetallePago.toggle();
        },
        async limpiar(){
            this.cargando = false;
            this.mostrarInfo = false;
            this.mostrarError = false;
            this.pedido = {};
            this.buscar = '';
            this.tipoError = '';
        },
        toggle(){
            this.limpiar();
            this.$refs.modalDetallePago.toggle();
        },
    }
}
</script>
